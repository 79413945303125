import { IconButton, Tooltip } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import React, { memo, useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { save, app } from '../../../../api/api';
import { fileIdState, groupIdState, lockedState } from '../../../pages/Editor/atoms';

const Save = memo(() => {
    const fileId = useRecoilValue(fileIdState);
    const locked = useRecoilValue(lockedState);
    const groupId = useRecoilValue(groupIdState);

    const save_ = useCallback(() => groupId && fileId && app.query(save({ group_id: groupId, env_id: fileId })).send(), [groupId, fileId]);

    useEffect(() => {
        const l = (event: KeyboardEvent) => {
            if (event.ctrlKey || event.metaKey) {
                switch (event.key.toLowerCase()) {
                    case 's':
                        event.preventDefault();
                        save_();
                        break;
                }
            }
        }
        window.addEventListener('keydown', l);
        return () => window.removeEventListener('keydown', l);
    }, [save_]);

    return (
        <>
            {fileId && groupId &&
                <Tooltip title="Save">
                    <IconButton disabled={locked} onClick={save_}>
                        <SaveOutlinedIcon color={locked ? "disabled" : "primary"} />
                    </IconButton>
                </Tooltip>
            }
        </>
    )
});

export default Save;