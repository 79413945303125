import { AppBar as MaterialUIAppBar, Tab, Tabs, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { app, useHandler } from '../../../api/api';
import { userState } from '../../../store/user/action';
import ProfileButton from './profileButton';
import { routes } from './routes';

const pagesTo = routes.map(route => route.to.split('/')[1]);

const getRoute = () => pagesTo.indexOf(document.location.pathname.split('/')[1]) || 0;

const AppBar = () => {
    const [user, setUser] = useRecoilState(userState);
    const role = user?.role;
    const [tab, setTab] = useState(getRoute());
    const handleChange = (_: any, newValue: any) => setTab(newValue);

    useHandler('get_account_data', ({ user }) => {
        setUser(user);
    });

    useEffect(() => {
        app.sendCommand('get_account_data', {});
    }, []);

    return (
        <MaterialUIAppBar position="static">
            <Toolbar>
                <Tabs style={{ display: 'flex', flex: 'space-around' }} value={tab} onChange={handleChange}>
                    {role && routes.filter(r => {
                        if (r.noBar) {
                            return false;
                        }
                        if (r.adminOnly) {
                            return role === 'admin';
                        }
                        return true;
                    }).map(r => (
                        <Tab label={r.title} to={r.to} component={Link} />
                    ))}
                </Tabs>
                <ProfileButton user={user} setTab={setTab} />
            </Toolbar>
        </MaterialUIAppBar>
    );
};

export default AppBar;