import React, { CSSProperties, ReactNode } from "react";
import { CircularProgress, makeStyles, Theme } from "@material-ui/core";

type Props = {
	style?: CSSProperties;
	loading?: boolean;
	children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
	div: (props: Props) => ({
		width: "100%",
		height: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: "1em",
		...props.style
	}),
	child: (props: Props) => ({ ...props.style }),
}));

export default (props: Props) => {
	const classes = useStyles(props);
	return props.loading ? (
		<div className={classes.div}>
			<CircularProgress />
		</div>
	) : (
			<div className={classes.child}>{props.children}</div>
		);
};
