import { Dialog, Fab } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";
import React, { useCallback, useState } from 'react';
import { app, create_token, delete_token, get_tokens, useHandler } from '../../../api/api';
import { TokenView } from '../../../api/types';
import { dateForHuman } from '../../../utils/utils';
import { useAlert } from '../Alert/atoms';
import DeleteButton from '../Button/DeleteButton';
import { useSnackbar } from '../Snackbar/atoms';
import DataTable from '../Table/DataTable';


const columns = [
    { title: "Value", name: 'value' },
    { title: "Created at", name: 'created_at' },
    { title: "Delete", name: 'delete' },
];

const Tokens = ({ open, handleModalClose, env_id, group_id }: { open: boolean, handleModalClose: () => void, env_id?: number, group_id?: number }) => {
    const [loading, setLoading] = useState(false);
    const snackbar = useSnackbar();
    const showAlert = useAlert();
    const [tokens, setTokens] = useState<TokenView[]>([]);

    const handleDeletion = useCallback((token_value: string) => {
        if (group_id && env_id) {
            showAlert("Do you really want to delete this token ?", () => {
                setLoading(true);
                app
                    .query(delete_token({ group_id, env_id, token_value }))
                    .after(get_tokens({ group_id, env_id }))
                    .send();
            })
        }
    }, [group_id, env_id, showAlert]);

    useHandler('create_token', ({ error }: { error: boolean }) => {
        if (error) {
            snackbar("error", 'Failed to create token');
        } else {
            snackbar("check", 'Successfully created token');
        }
    });

    useHandler('delete_token', ({ error }: { error: boolean }) => {
        if (error) {
            snackbar("error", 'Failed to create token');
        } else {
            snackbar("check", 'Successfully deleted token');
        }
    });

    useHandler('get_tokens', ({ tokens }) => {
        setLoading(false);
        setTokens(tokens);
    });

    const handle_create = () => {
        if (env_id && group_id) {
            setLoading(true);
            app
                .query(create_token({ env_id, group_id }))
                .after(get_tokens({ env_id, group_id }))
                .send();
        }
    };


    const data = tokens.map(token => ({
        ...token,
        created_at: dateForHuman(token.created_at),
        delete: (
            <DeleteButton style={{ margin: '1%' }} onClick={() => handleDeletion(token.value)} />
        )
    }));

    const loadData = () => {
        if (env_id && group_id) {
            setLoading(true);
            app
                .query(get_tokens({ env_id, group_id }))
                .send();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setTokens([]);
                handleModalClose();
            }}
            onEnter={loadData}
        >

            <DataTable
                loading={loading}
                title={
                    <div>
                        Token
                        <Fab size="small" style={{ marginLeft: '2em' }} onClick={handle_create}>
                            < AddIcon />
                        </Fab>
                    </div>
                }
                data={data}
                columns={columns}
            />

        </Dialog>
    );
}

export default Tokens;