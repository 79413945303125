import React from 'react';
import SelectField from '../../../Form/SelectField';
import { useRecoilState } from 'recoil';
import { outputOptions, Language } from '../../../../pages/Editor/atoms';

const languages: Language[] = ["yaml", "flat", "json"];
const options = languages.map(e => ({ value: e, label: e }));


const LanguageSelector = () => {

    const [option, setLanguage] = useRecoilState(outputOptions);

    return (
        <SelectField
            initialValue={{label:option.language, value:option.language}}
            label="Language"
            options={options}
            onChange={e => setLanguage({ language: e.target.value.value })}
        />
    )
}

export default LanguageSelector;