import { Menu, MenuItem } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { MenuOption } from "./useOptions";

export function useMenu<T>(options: MenuOption<T>[], filter: (options: MenuOption<T>, selected: T) => boolean): [JSX.Element, (x: number, y: number) => void, React.Dispatch<T | undefined>] {
    const [selected, setSelected] = useState<T | undefined>();
    const [mouseY, setMouseY] = useState<number>();
    const [mouseX, setMouseX] = useState<number>();
    const open = Boolean(mouseY);
  
    const handleClose = useCallback(() => {
      setMouseY(undefined);
    }, []);
  
    const setAnchorEl = (x: number, y: number) => {
      setMouseX(x);
      setMouseY(y);
    };
  
    const _filter = useCallback((option: MenuOption<T>) => filter(option, selected!), [selected, filter]);
  
    const mappedOptions = useMemo(() => (
      options.filter(_filter).map(o => (
        <MenuItem onClick={() => o.handler(selected!, handleClose)}>
          {o.label}
        </MenuItem>
      ))
    ), [options, handleClose, selected, _filter]);
  
    const m = (
      <Menu
        keepMounted
        onContextMenu={e => {
          e.preventDefault();
          handleClose();
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY && mouseX
            ? { top: mouseY, left: mouseX }
            : undefined
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
      >
        {mappedOptions}
      </Menu>
    )
  
    return [m, setAnchorEl, setSelected];
  }