import React, { memo } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { app, get_ownership } from '../../../../api/api';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { lockedState, freedState, fileIdState } from '../../../pages/Editor/atoms';
import { useRecoilValue } from 'recoil';



const Lock = memo(() => {
    const locked = useRecoilValue(lockedState);
    const freed = useRecoilValue(freedState);
    const fileId = useRecoilValue(fileIdState);
    return (
        <>
            {locked ?
                freed ? (
                    <Tooltip title="Click to get ownership">
                        <IconButton onClick={() => app.query(get_ownership({ env_id: fileId! })).send()} color="primary">
                            <LockOpenIcon />
                        </IconButton>
                    </Tooltip>
                )
                    : (
                        <Tooltip title="File in read-only mode">
                            <span>
                                {/* For the tooltip to work on disabled element */}
                                <IconButton disabled>
                                    <LockIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    )
                : (
                    <Tooltip title="File unlocked">
                        <span>
                            {/* For the tooltip to work on disabled element */}
                            <IconButton disabled style={{ color: 'green' }}>
                                <LockOpenIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    // locked && freed => blue unlocked
                    // locked && !freed => gray locked
                    // !locked => gray unlocked
                )}
        </>
    );
});

export default Lock;