import React, { memo } from 'react';
import { app, create_group, get_filenames } from '../../../../api/api';
import { Tooltip, IconButton } from '@material-ui/core';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import { usePrompt } from '../../Prompt/atoms';

const NewGroup = memo(({ selectedGroupId }: { selectedGroupId?: number }) => {
    const prompt = usePrompt({ 'groupName': {} });

    const handleCreateGroup = async () => {
        if (selectedGroupId) {
            const { groupName } = await prompt();
            if (groupName) {
                app
                    .query(create_group({ group_id: selectedGroupId, group_name: groupName }))
                    .after(get_filenames())
                    .send();
            }
        }
    };

    return (
        <Tooltip title="New group">
            <IconButton onClick={handleCreateGroup}>
                <CreateNewFolderOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
});


export default NewGroup;