import { atom } from 'recoil';



export const fileIdState = atom<number | undefined>({
    key: 'currentFileId',
    default: undefined,
})

export const fileNameState = atom<string | undefined>({
    key: 'currentFileName',
    default: '',
});

export const groupIdState = atom<number | undefined>({
    key: 'currentGroupId',
    default: undefined,
})

export const groupNameState = atom({
    key: 'currentGroupName',
    default: '',
})

export const lockedState = atom({
    key: 'editorLocked',
    default: true,
});

export const freedState = atom({
    key: 'editorFreed',
    default: false,
});

export type Language = "flat" | "yaml" | "json";

export type EditorOptions = {
    language: Language;
}

export const outputOptions = atom<EditorOptions>({
    key: 'editorOptions',
    default: {
        language: "yaml",
    },
})