import React, { memo } from 'react';
import { app, create_env, get_filenames } from '../../../../api/api';
import { Tooltip, IconButton } from '@material-ui/core';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import { usePrompt } from '../../Prompt/atoms';


const NewFile = memo(({ selectedGroupId }: { selectedGroupId?: number }) => {
    const prompt = usePrompt({ env_name: {} }, 'New File');
    
    const handleCreateEnv = async () => {
        if (selectedGroupId) {
            const { env_name } = await prompt();
            if (env_name) {
                app
                    .query(create_env({ env_name, group_id: selectedGroupId }))
                    .after(get_filenames())
                    .send();
            }
        }
    };
    return (
        <Tooltip title="New file">
            <IconButton onClick={handleCreateEnv}>
                <NoteAddOutlinedIcon />
            </IconButton>
        </Tooltip>
    );
});

export default NewFile;