import React, { Component, ReactText } from "react";
import {
	ColumnChooser,
	DragDropProvider,
	Grid,
	SearchPanel,
	TableColumnReordering,
	TableColumnVisibility,
	TableEditColumn,
	TableFilterRow,
	TableHeaderRow,
	TableRowDetail,
	TableSelection,
	Toolbar,
	ToolbarProps,
	VirtualTable,
	Table,
} from "@devexpress/dx-react-grid-material-ui";
import DoneIcon from "@material-ui/icons/Done";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {
	Column,
	EditingState,
	FilteringState,
	IntegratedFiltering,
	IntegratedSorting,
	RowDetailState,
	SearchState,
	SelectionState,
	SortingState,
	Filter,
} from "@devexpress/dx-react-grid";
import IconButton from "@material-ui/core/IconButton";
// import { sharedParse } from "../../../utils/format";
import { Theme, withTheme } from "@material-ui/core";
import ReloadButton from "./ReloadButton";

const ToolbarFixed = (props: ToolbarProps & { selection: number[] }) => <Toolbar {...props} />;


type ExtendedColumnType = Column & {
	predicate?: (value: any, filter: Filter) => boolean;
	width?: string;
}

type OwnProps = {
	defaultSorting?: { columnName: string; direction: "desc" | "asc" };
	data: Object[];
	title: string | ReactText | JSX.Element;
	columns: ExtendedColumnType[];
	detailComponent?: any;
	maskColumns?: boolean;
	onSelectValidate?: (rows: any[]) => void;
	onRowClick?: (rowData: any) => void;
	onRowEdit?: (row: number) => void;
	onRowDelete?: (row: number) => void;
	onRowSelected?: (rows: number[]) => void;
	loading?: boolean;
	onReload?: () => Promise<void>;
	theme: Theme;
};

type Props = OwnProps;
type State = {
	loading: boolean;
	order: string[];
	selection: number[];
	height: number;
};

// const compareDate = (a: string, b: string) => (sharedParse(a) > sharedParse(b) ? 1 : -1);

const EditingHeaderComponentBase = (props: any) => {
	return (
		<div
			{...props}
			style={{
				color: "#8A73B4",
				textAlign: "center",
				fontSize: "0.75rem",
				fontWeight: "500",
				lineHeight: "1.3125rem",
				display: "table-cell",
				padding: "14px 0px 14px 0px",
			}}>
			Gérer
		</div>
	);
};


class DataTable extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			height: 530,
			loading: false,
			order: this.props.columns.map((column: Column) => column.name),
			selection: [],
		};
	}
	divElement: HTMLElement | null = null;
	componentDidMount() {
		const height = this.divElement!.parentElement!.clientHeight * 0.78;
		this.setState({ height: Math.max(height, 530) });
	}
	static defaultProps = { maskColumns: false };

	TableRow = (props: Table.RowProps) => (
		<Table.Row
			{...props}
			row={props.tableRow}
			onClick={() => {
				if (this.props.onRowClick) this.props.onRowClick!(props.tableRow.row);
			}}
			style={{
				cursor: this.props.onRowClick ? "pointer" : "default",
			}}
		/>
	);

	ToolbarRoot = (props: any) => (
		<div>
			<Toolbar.Root
				style={{
					backgroundColor: this.props.theme.palette.primary.main,
					color: this.props.theme.palette.primary.contrastText,
					borderRadius: "3px 3px 0px 0px",
				}}>
				<Typography variant="h6">{this.props.title}</Typography>
				{props.children}
			</Toolbar.Root>
		</div>
	);

	ToolbarSelect = (props: any) => (
		<div>
			<Toolbar.Root style={{ color: "white", backgroundColor: "darkgray", borderRadius: "3px 3px 0px 0px" }}>
				{props.children}
				<IconButton
					onClick={() => {
						this.props.onSelectValidate!(this.state.selection);
						this.setState({ selection: [] });
					}}>
					<DoneIcon />
				</IconButton>
			</Toolbar.Root>
		</div>
	);

	changeSelection = (selection: any) => this.setState({ selection });
	onOrderChange = (order: string[]) => this.setState({ order });

	onReload = async () => {
		this.setState({ loading: true });
		this.props.onReload && (await this.props.onReload());
		this.setState({ loading: false });
	};

	render() {
		const { columns, loading, onSelectValidate, defaultSorting, maskColumns, data, detailComponent, onRowDelete, onRowEdit } = this.props;
		const { order, selection } = this.state;
		const tableColumnExtensions = columns.map(col => ({
			columnName: col.name,
			wordWrapEnabled: true,
			width: col.width
		}));
		const filteringExtensions = columns.map(col => ({ columnName: col.name, predicate: col.predicate }));
		return (
			<Paper
				elevation={4}
				style={{ margin: 5 }}
				ref={(divElement: HTMLElement) => {
					this.divElement = divElement;
				}}>
				<Grid rows={data} columns={columns}>
					<FilteringState defaultFilters={[]} />
					<SearchState />
					<DragDropProvider />
					<SortingState defaultSorting={[defaultSorting || { columnName: "id", direction: "desc" }]} />
					{onSelectValidate && <SelectionState selection={selection} onSelectionChange={this.changeSelection} />}
					<EditingState
						onCommitChanges={() => console.log("a implementer plus tard")}
						onDeletedRowIdsChange={row => console.log("a implementer plus tard")}
						onEditingRowIdsChange={z => console.log("a implementer plus tard")}
						onRowChangesChange={z => console.log("a implementer plus tard")}
					/>
					{detailComponent && <RowDetailState />}
					<IntegratedSorting />
					<IntegratedFiltering columnExtensions={filteringExtensions} />
					<VirtualTable rowComponent={this.TableRow} columnExtensions={tableColumnExtensions} height={this.state.height} />
					<TableColumnReordering order={order} onOrderChange={this.onOrderChange} />
					<TableHeaderRow showSortingControls />
					<TableFilterRow />
					{onRowEdit && onRowDelete && <TableEditColumn headerCellComponent={EditingHeaderComponentBase} showEditCommand showDeleteCommand />}
					{detailComponent && <TableRowDetail contentComponent={detailComponent} />}
					{maskColumns && <TableColumnVisibility />}
					<ToolbarFixed rootComponent={selection.length > 0 ? this.ToolbarSelect : this.ToolbarRoot} selection={selection} />
					<ReloadButton loading={loading} getData={this.props.onReload ? this.onReload : undefined} />
					{maskColumns && <ColumnChooser />}
					<SearchPanel />
					{onSelectValidate && <TableSelection />}
				</Grid>
			</Paper>
		);
	}
}

export default withTheme(DataTable);
