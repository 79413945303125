import { atom, useRecoilValue } from "recoil";
import { UserState } from "./type";

export const userState = atom<UserState>({
    key: 'userState',
    default: undefined
});

export const useUser = ()=>{
    const state = useRecoilValue(userState);
    return state;
}