import { makeStyles, Theme, Typography } from "@material-ui/core";
import React, { memo } from "react";
import { SelectedFile } from "../EnvEditor/MenuContent/useOptions";

type FolderLabelProps = {
    name: string;
    id: number;
    setMenuAnchor: (x:number, y:number)=>void;
    setSelectGroupId: React.Dispatch<number>;
    setSelectedGroupName: React.Dispatch<string>;
    setSelectedFile: React.Dispatch<SelectedFile | undefined>;
}

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        height: '1em',
        width: '1em',
        paddingRight: 'auto',
    },
    icon: {

    },
    div: {
        // display: 'flex',
        // marginRight: 'auto',
    }
}));

export const FolderLabel = memo((props: FolderLabelProps) => {
    const { name, setSelectGroupId, setSelectedGroupName, id, setMenuAnchor, setSelectedFile } = props;
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.div} onClick={() => {
                setSelectGroupId(id);
                setSelectedGroupName(name);

            }}
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedFile({ fileId: undefined, groupId: id });
                    // setMenuAnchor(e.currentTarget);
                    setMenuAnchor(e.clientX, e.clientY);
                }}
            >
                {name}
            </Typography >
        </>
    );
});


export default FolderLabel;