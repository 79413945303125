import { Card, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHandler } from '../../../../api/api';
import { GroupView } from '../../../../api/types';
import { useUser } from '../../../../store/user/action';
import { useTitle } from '../../../../utils/utils';
import { fileNameState, groupIdState, groupNameState } from '../../../pages/Editor/atoms';
import FileTree from '../../FileTree/FileTree';
import { useSnackbar } from '../../Snackbar/atoms';
import Lock from './Lock';
import MoreOptions from './MoreOptions/MoreOptions';
import NewFile from './NewFile';
import NewGroup from './NewGroup';
import Save from './Save';
import { useMenu } from './useMenu';
import { MenuOption, SelectedFile, useOptions } from './useOptions';

type Props = {
  group?: GroupView;
};

const useStyles = makeStyles(() => ({
  card: {
    paddingLeft: '0.4em',
    paddingRight: '0.4em',
    margin: '0.3em',
    display: 'flex',
    alignItems: 'center',
  },
  div: {
    width: '20vw',
  },
}));

function optionFilter(option: MenuOption<SelectedFile>, selected?: SelectedFile) {
  if (selected) {
    let isFile = false;
    if (selected.fileId) {
      isFile = true;
    }
    if (option.for.includes("file") && isFile) {
      return true;
    }
    if (option.for.includes('group') && !isFile) {
      return true;
    }
  }
  return false;
}

const MenuContent = (props: Props) => {
  const user = useUser();
  const { group } = props;
  const snackbar = useSnackbar();
  const selectedGroupId = useRecoilValue(groupIdState);
  const username = user?.username;

  const [selectedGroupName, setSelectedGroupName] = useRecoilState(groupNameState);
  const selectedFilename = useRecoilValue(fileNameState);

  useTitle(`Editing : ${selectedFilename || '<Empty>'}`);

  useEffect(() => {
    username && setSelectedGroupName(username);
  }, [username, setSelectedGroupName]);

  useHandler('create_env', ({ error }: { error: boolean }) => {
    if (error) {
      snackbar('error', 'Failed to create env')
    } else {
      snackbar("check", 'Successfully created env');
    }
  });
  useHandler('delete_env', ({ error }: { error: boolean }) => {
    if (error) {
      snackbar('error', 'Failed to delete env');
    } else {
      snackbar('check', 'Successfully deleted env');
    }
  });
  useHandler('create_group', ({ error }: { error: boolean }) => {
    if (error) {
      snackbar('error', 'Failed to create group');
    } else {
      snackbar('check', 'Successfully created group');
    }
  });

  useHandler('add_user_to_group', ({ error }: { error: boolean }) => {
    if (error) {
      snackbar('error', 'Failed to add to group');
    } else {
      snackbar('check', 'Successfully added to group');
    }
  });

  const classes = useStyles();
  const { options, UserSelector, TokenModal } = useOptions();
  const [OptionMenu, setMenuAnchor, setSelectedFile] = useMenu(options, optionFilter);

  return (
    <>
      {TokenModal}
      {OptionMenu}
      {UserSelector}
      <div className={classes.div}>
        <Card elevation={5} className={classes.card}>
          {/* TODO: Fix => not updated */}

          <Typography>
            {selectedGroupName}
          </Typography>

          <NewFile selectedGroupId={selectedGroupId} />
          <NewGroup selectedGroupId={selectedGroupId} />
          <Save />
          <Lock />
          <MoreOptions />
        </Card>
        {group && (
          <FileTree
            group={group}
            setMenuAnchor={setMenuAnchor}
            setSelectedFile={setSelectedFile}
          />
        )}
      </div>
    </>
  )
}


export default MenuContent;