import { makeStyles } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import React, { memo, useCallback } from "react";
import { SetterOrUpdater, useSetRecoilState } from 'recoil';
import { GroupView } from "../../../api/types";
import { fileNameState, groupIdState, groupNameState } from "../../pages/Editor/atoms";
import FileLabel from "./FileLabel";
import FolderLabel from './FolderLabel';
import { useHistory } from "react-router-dom";
import { SelectedFile } from "../EnvEditor/MenuContent/useOptions";

const useStyles = makeStyles({
    root: {
        // height: 110,
        flexGrow: 1,
        width: '100%',
    },
});

type RenderTreeProps = {
    group: GroupView;
    precName: string;
    onClick: (group_id: number, file_id: number) => void;
    setSelectGroupId: SetterOrUpdater<number | undefined>;
    setSelectedGroupName: SetterOrUpdater<string>;
    setSelectedFilename: SetterOrUpdater<string | undefined>;
    setMenuAnchor: (x: number, y: number) => void;
    setSelectedFile: React.Dispatch<SelectedFile | undefined>;
};




const RenderTree = memo(
    ({ group, onClick, setSelectGroupId, setSelectedGroupName, setSelectedFilename, precName = '', setMenuAnchor, setSelectedFile }: RenderTreeProps) => (
        <TreeItem
            key={group.name}
            nodeId={'' + group.name}
            label={<FolderLabel
                id={group.id}
                setMenuAnchor={setMenuAnchor}
                setSelectedFile={setSelectedFile}
                name={group.name}
                setSelectGroupId={setSelectGroupId}
                setSelectedGroupName={setSelectedGroupName}
            />}>
            {group.files.map(f => (
                <TreeItem
                    key={f.file_id}
                    nodeId={`${precName}/${group.name}/${f.filename}`}
                    label={<FileLabel
                        setMenuAnchor={setMenuAnchor}
                        name={f.filename}
                        onClick={onClick}
                        env_id={f.file_id}
                        group_name={group.name}
                        group_id={group.id}
                        setSelectedGroupId={setSelectGroupId}
                        setSelectedFilename={setSelectedFilename}
                        setSelectedGroupName={setSelectedGroupName}
                        setSelectedFile={setSelectedFile}
                    />}
                />
            ))}
            {group.sub_groups.map(node => (
                <RenderTree
                    setMenuAnchor={setMenuAnchor}
                    setSelectedFile={setSelectedFile}
                    setSelectedFilename={setSelectedFilename}
                    setSelectedGroupName={setSelectedGroupName}
                    setSelectGroupId={setSelectGroupId}
                    group={node}
                    precName={node.name}
                    onClick={onClick} />
            ))}
        </TreeItem >
    ));


type FileTreeProps = {
    group: GroupView;
    setMenuAnchor: (x: number, y: number) => void;
    setSelectedFile: React.Dispatch<SelectedFile | undefined>;
};

const FileTree = (props: FileTreeProps) => {
    const { group, setMenuAnchor, setSelectedFile } = props;
    const setSelectedFilename = useSetRecoilState(fileNameState);
    const setSelectedGroupName = useSetRecoilState(groupNameState);
    const setSelectGroupId = useSetRecoilState(groupIdState);

    const classes = useStyles();
    const history = useHistory();

    const pushUrl = useCallback(
        (group_id: number, file_id: number) => {
            history.push(`/editor/${group_id}/${file_id}`)
        }, [history]);

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {group.files.map(f => (
                <TreeItem
                    key={f.file_id}
                    nodeId={group.name + f.filename}
                    label={<FileLabel
                        name={f.filename}
                        env_id={f.file_id}
                        group_id={group.id}
                        group_name={group.name}
                        onClick={pushUrl}
                        setMenuAnchor={setMenuAnchor}

                        setSelectedFilename={setSelectedFilename}
                        setSelectedGroupName={setSelectedGroupName}
                        setSelectedGroupId={setSelectGroupId}
                        setSelectedFile={setSelectedFile}
                    />}
                />
            ))}
            {group.sub_groups.map(g => (
                <RenderTree
                    group={g}
                    precName={group.name}
                    onClick={pushUrl}
                    setMenuAnchor={setMenuAnchor}

                    setSelectGroupId={setSelectGroupId}
                    setSelectedGroupName={setSelectedGroupName}
                    setSelectedFilename={setSelectedFilename}
                    setSelectedFile={setSelectedFile}
                />
            ))
            }
        </TreeView>
    );
}

export default FileTree;