import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { app } from './api/api';
import Router from './components/common/Router/Router';
import { loggedInState } from './components/pages/Login/atoms';
import LoginPage from './components/pages/Login/logger';
import { themeState, getSettings } from './theme';

function useTheme() {
  const [themeValue, setThemeValue] = useRecoilState(themeState);
  useEffect(() => {
    setThemeValue(getSettings());
  }, [setThemeValue]);

  const theme = createMuiTheme({ palette: { type: themeValue.type } });
  return theme;
}

const App = () => {
  const theme = useTheme();
  const loggedIn = useRecoilValue(loggedInState);
  
  // intialize app
  useEffect(() => {
    app.start();
  }, []);

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {
          (loggedIn === null || loggedIn === false) ?
            <LoginPage />
            :
            <Router />
        }
      </ThemeProvider>
  );
};


export default App;

