import { Dialog } from '@material-ui/core';
import React, { useState } from 'react';
import DataTable from '../Table/DataTable';

type Props = {
    users: { username: string }[];
    title: string;
    loading?: boolean;
    onSelect: (username: string) => void;
    open: boolean;
    handleModalClose: () => void;
}

const columns = [
    { title: 'Username', name: 'username' },
]

const UserSelector = (props: Props) => {
    const { open, handleModalClose: handleClose, title, users, onSelect, loading } = props;
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <div>
                <DataTable
                    title={title}
                    data={users}
                    loading={loading}
                    columns={columns}
                    onRowClick={(row: { username: string }) => onSelect(row.username)}
                />
            </div>
        </Dialog>
    );
}
export default UserSelector;


export function useUserSelector(title: string, users: { username: string }[], onSelect: (username: string) => void, loading = false) {
    const [open, setOpen_] = useState(false);
    const handleModalClose = () => setOpen_(false);
    const setOpen = () => setOpen_(true);
    const Modal = (
        <UserSelector
            open={open}
            loading={loading}
            handleModalClose={handleModalClose}
            title={title}
            users={users}
            onSelect={onSelect}
        />
    );
    return { Selector: Modal, setOpen, setClose: handleModalClose };
}