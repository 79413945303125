import { makeStyles, Theme, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { SelectedFile } from '../EnvEditor/MenuContent/useOptions';


type FileLabelProps = {
    name: string;
    group_name: string;
    group_id: number;
    env_id: number;
    onClick: (group_id: number, env_id: number) => void;
    setSelectedFilename: React.Dispatch<string>;
    setSelectedGroupId: React.Dispatch<number>;
    setSelectedGroupName: React.Dispatch<string>;
    setMenuAnchor: (x:number, y:number)=>void;
    setSelectedFile: React.Dispatch<SelectedFile | undefined>;
}

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        height: '1em',
        width: '1em'
    },
    icon: {

    },
    div: {
        // display: 'flex',
        marginRight: 'auto',
    }
}));

export const FileLabel = memo((props: FileLabelProps) => {
    const { name, setSelectedFilename, env_id, group_id, onClick,
        setSelectedGroupName, group_name, setSelectedGroupId, setMenuAnchor,
        setSelectedFile, } = props;
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.div}
                onContextMenu={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // setMenuAnchor(e.currentTarget);
                    setMenuAnchor(e.clientX, e.clientY);
                    setSelectedFile({ groupId: group_id, fileId: env_id });
                }}
                onClick={() => {
                    setSelectedFilename(name);
                    setSelectedGroupId(group_id);
                    setSelectedGroupName(group_name);
                    onClick(group_id, env_id);
                }}>
                {name}
            </Typography >
        </>
    );
});

export default FileLabel;


