import { lazy } from 'react';
import Editor from '../../pages/Editor/Editor';
const ManageAccounts = lazy(() => import('../../pages/ManageAccounts/ManageAccounts'));
const MyAccount = lazy(() => import('../../pages/MyAccount/MyAccount'));
const HowTo = lazy(() => import('../../pages/HowTo/HowTo'));

export const routes = [
    { title: 'Editor', to: '/editor/:group_id?/:env_id?', component: Editor, },
    { title: 'How to', to: '/howTo', component: HowTo },
    { title: 'Accounts', to: '/accounts', component: ManageAccounts, adminOnly: true },
    { title: 'Profile', to: '/profile', component: MyAccount, noBar: true },
];

