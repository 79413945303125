/*
README.md
# Warnings

* Don't forget to app.start()
* Don't forget to import the WS Lib

*/
// <===== Generated skeleton ======> //


// espacing { for python }
import WebsocketedCon, { makeSender, makeTokenManager } from './webSocketedCon';
import { useEffect } from 'react';

// should use a more general method to inject the adress
const addr = process.env.REACT_APP_ADDRESS;

if (!addr) {
	throw new Error('missing ws address');
}

/**
 * The websocket and the authentication of the app is all handled here
 */
export const app = new WebsocketedCon(addr);

export type CreateUserDto = {
	username: string;
	password: string;
	role: UserType;
};
export type TokenRepr = {
	env_id: number /*int*/;
	group_id: number /*int*/;
	token_value: string;
};
export type PagedResult<T> = {
	content: T[];
	totalElements: number /*int*/;
};
export type GroupView = {
	group_name: string;
	group_id: number /*int*/;
	files: { [key: string]: string };
};
export type UserDto = {
	username: string;
	role: UserType;
	allies: string[];
	messages_nb: number /*int*/;
};
export type UserType = "admin" | "user";

// receivers

type handlersName = func_test_upload_Name | func_create_user_Name | func_get_users_Name | func_get_filenames_Name | func_update_env_Name | func_suscribe_env_Name | func_unsuscribe_env_Name | func_create_token_Name | func_invalidate_token_Name | func_delete_token_Name | func_get_tokens_Name | func_create_env_Name | func_save_Name | func_delete_env_Name | func_get_ownership_Name | func_add_user_to_group_Name | func_create_group_Name | func_get_account_data_Name | func_get_allies_Name | func_change_password_Name | func_get_messages_Name | func_decrypt_message_Name | func_ask_ally_Name | func_handle_invitation_Name;
type handlersProto = func_test_upload_Proto | func_create_user_Proto | func_get_users_Proto | func_get_filenames_Proto | func_update_env_Proto | func_suscribe_env_Proto | func_unsuscribe_env_Proto | func_create_token_Proto | func_invalidate_token_Proto | func_delete_token_Proto | func_get_tokens_Proto | func_create_env_Proto | func_save_Proto | func_delete_env_Proto | func_get_ownership_Proto | func_add_user_to_group_Proto | func_create_group_Proto | func_get_account_data_Proto | func_get_allies_Proto | func_change_password_Proto | func_get_messages_Proto | func_decrypt_message_Proto | func_ask_ally_Proto | func_handle_invitation_Proto;
type func_test_upload_Name = "test_upload";
type func_test_upload_Proto = (params: any) => void;
type func_create_user_Name = "create_user";
type func_create_user_Proto = (params: { success: boolean }) => void;
type func_get_users_Name = "get_users";
type func_get_users_Proto = (params: PagedResult<{ username: string, id: number /*int*/ }>) => void;
type func_get_filenames_Name = "get_filenames";
type func_get_filenames_Proto = (params: { filenames: GroupView[] }) => void;
type func_update_env_Name = "update_env";
type func_update_env_Proto = (params: { error: string }) => void;
type func_suscribe_env_Name = "suscribe_env";
type func_suscribe_env_Proto = (params: undefined) => void;
type func_unsuscribe_env_Name = "unsuscribe_env";
type func_unsuscribe_env_Proto = (params: undefined) => void;
type func_create_token_Name = "create_token";
type func_create_token_Proto = (params: { error: boolean }) => void;
type func_invalidate_token_Name = "invalidate_token";
type func_invalidate_token_Proto = (params: { error: boolean }) => void;
type func_delete_token_Name = "delete_token";
type func_delete_token_Proto = (params: { error: boolean }) => void;
type func_get_tokens_Name = "get_tokens";
type func_get_tokens_Proto = (params: { tokens: { value: string, created_at: string }[] }) => void;
type func_create_env_Name = "create_env";
type func_create_env_Proto = (params: { error: boolean }) => void;
type func_save_Name = "save";
type func_save_Proto = (params: { error: boolean }) => void;
type func_delete_env_Name = "delete_env";
type func_delete_env_Proto = (params: { error: boolean }) => void;
type func_get_ownership_Name = "get_ownership";
type func_get_ownership_Proto = (params: { error: boolean }) => void;
type func_add_user_to_group_Name = "add_user_to_group";
type func_add_user_to_group_Proto = (params: { error: boolean }) => void;
type func_create_group_Name = "create_group";
type func_create_group_Proto = (params: { error: boolean }) => void;
type func_get_account_data_Name = "get_account_data";
type func_get_account_data_Proto = (params: { user: UserDto }) => void;
type func_get_allies_Name = "get_allies";
type func_get_allies_Proto = (params: { allies: string[] }) => void;
type func_change_password_Name = "change_password";
type func_change_password_Proto = (params: { error: boolean }) => void;
type func_get_messages_Name = "get_messages";
type func_get_messages_Proto = (params: { messages: number[] }) => void;
type func_decrypt_message_Name = "decrypt_message";
type func_decrypt_message_Proto = (params: { content: any, id: number /*int*/ }) => void;
type func_ask_ally_Name = "ask_ally";
type func_ask_ally_Proto = (params: { error: boolean }) => void;
type func_handle_invitation_Name = "handle_invitation";
type func_handle_invitation_Proto = (params: { error: boolean }) => void;
export function useHandler(handlerName: func_test_upload_Name, handler: func_test_upload_Proto): void;
export function useHandler(handlerName: func_create_user_Name, handler: func_create_user_Proto): void;
export function useHandler(handlerName: func_get_users_Name, handler: func_get_users_Proto): void;
export function useHandler(handlerName: func_get_filenames_Name, handler: func_get_filenames_Proto): void;
export function useHandler(handlerName: func_update_env_Name, handler: func_update_env_Proto): void;
export function useHandler(handlerName: func_suscribe_env_Name, handler: func_suscribe_env_Proto): void;
export function useHandler(handlerName: func_unsuscribe_env_Name, handler: func_unsuscribe_env_Proto): void;
export function useHandler(handlerName: func_create_token_Name, handler: func_create_token_Proto): void;
export function useHandler(handlerName: func_invalidate_token_Name, handler: func_invalidate_token_Proto): void;
export function useHandler(handlerName: func_delete_token_Name, handler: func_delete_token_Proto): void;
export function useHandler(handlerName: func_get_tokens_Name, handler: func_get_tokens_Proto): void;
export function useHandler(handlerName: func_create_env_Name, handler: func_create_env_Proto): void;
export function useHandler(handlerName: func_save_Name, handler: func_save_Proto): void;
export function useHandler(handlerName: func_delete_env_Name, handler: func_delete_env_Proto): void;
export function useHandler(handlerName: func_get_ownership_Name, handler: func_get_ownership_Proto): void;
export function useHandler(handlerName: func_add_user_to_group_Name, handler: func_add_user_to_group_Proto): void;
export function useHandler(handlerName: func_create_group_Name, handler: func_create_group_Proto): void;
export function useHandler(handlerName: func_get_account_data_Name, handler: func_get_account_data_Proto): void;
export function useHandler(handlerName: func_get_allies_Name, handler: func_get_allies_Proto): void;
export function useHandler(handlerName: func_change_password_Name, handler: func_change_password_Proto): void;
export function useHandler(handlerName: func_get_messages_Name, handler: func_get_messages_Proto): void;
export function useHandler(handlerName: func_decrypt_message_Name, handler: func_decrypt_message_Proto): void;
export function useHandler(handlerName: func_ask_ally_Name, handler: func_ask_ally_Proto): void;
export function useHandler(handlerName: func_handle_invitation_Name, handler: func_handle_invitation_Proto): void;


export function useHandler(name: handlersName, func: handlersProto) {
	useEffect(() => {
		const removeHandler = app.on(name, func);
		return () => { removeHandler(); };
	}, [name, func]);
}

// requesters
export const test_upload = makeSender<object>('test_upload');
export const create_user = makeSender<CreateUserDto>('create_user');
export const get_users = makeSender<any>('get_users');
export const get_filenames = makeSender<void>('get_filenames');
export const update_env = makeSender<{ env_id: number /*int*/, content: string }>('update_env');
export const suscribe_env = makeSender<{ group_id: number /*int*/, env_id: number /*int*/ }>('suscribe_env');
export const unsuscribe_env = makeSender<{ env_id: number /*int*/ }>('unsuscribe_env');
export const create_token = makeSender<{ env_id: number /*int*/, group_id: number /*int*/ }>('create_token');
export const invalidate_token = makeSender<TokenRepr>('invalidate_token');
export const delete_token = makeSender<TokenRepr>('delete_token');
export const get_tokens = makeSender<{ env_id: number /*int*/, group_id: number /*int*/ }>('get_tokens');
export const create_env = makeSender<{ env_name: string, group_id: number /*int*/ }>('create_env');
export const save = makeSender<{ env_id: number /*int*/, group_id: number /*int*/ }>('save');
export const delete_env = makeSender<{ env_id: number /*int*/, group_id: number /*int*/ }>('delete_env');
export const get_ownership = makeSender<{ env_id: number /*int*/ }>('get_ownership');
export const add_user_to_group = makeSender<{ group_id: number /*int*/, username: string, password: string }>('add_user_to_group');
export const create_group = makeSender<{ group_id: number /*int*/, group_name: string }>('create_group');
export const get_account_data = makeSender<void>('get_account_data');
export const get_allies = makeSender<void>('get_allies');
export const change_password = makeSender<{ new_password: string, old_password: string }>('change_password');
export const get_messages = makeSender<void>('get_messages');
export const decrypt_message = makeSender<{ message_id: number /*int*/, user_password: string }>('decrypt_message');
export const ask_ally = makeSender<{ username: string, password: string }>('ask_ally');
export const handle_invitation = makeSender<{ message_id: number /*int*/, accept: boolean, user_password: string }>('handle_invitation');


// <===== Generated skeleton ======> //

/**
 * Name under which the token of the connection is registered
 */
const tokenName = 'ws_token';

export const { getToken, setToken, deleteToken } = makeTokenManager(tokenName);