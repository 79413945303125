import { ControlledEditor } from '@monaco-editor/react';
import React from 'react';
import Recoil, { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { app, save, update_env, suscribe_env, unsuscribe_env } from '../../../api/api';
import { freedState, lockedState, EditorOptions, outputOptions, Language } from '../../pages/Editor/atoms';
import { useSnackbar } from '../Snackbar/atoms';
import { makeFluxName, Types, renderers } from './utils';


const BUF_REFRESH_TIME = 150; //ms

type Props = {
  env_id?: number;
  group_id?: number;
  setSuccess: (msg: string) => void;
  setFailure: (msg: string) => void;
  setInfo: (msg: string) => void;
  setFreed: Recoil.SetterOrUpdater<boolean>;
  setLocked: Recoil.SetterOrUpdater<boolean>;
  locked: boolean;
  outputOptions: EditorOptions;
};

type stateProps = {
  input: string;
  result: string;
  toUpload: string;
  pureBuilt: string;
}

class Editor extends React.Component<Props, stateProps> {

  state: stateProps = {
    input: '',
    result: '',
    toUpload: '',
    pureBuilt: '',
  }

  unMountSourceFlux?: () => void;
  unMountBuiltFlux?: () => void;
  timeoutBuf?: number;
  gotSource: boolean;
  editor?: Element;

  constructor(props: Props) {
    super(props);
    this.unMountSourceFlux = undefined;
    this.unMountBuiltFlux = undefined;
    this.timeoutBuf = undefined;
    this.editor = undefined;
    this.gotSource = false;

    const { setFailure, setSuccess } = this.props;

    app.on('update_env', () => {

    });

    app.on('save', ({ error, to_reload }: { error: boolean, to_reload: string[] }) => {
      if (error) {
        setFailure("Failed to save env");
      } else {
        setSuccess(`Successfully saved env \nYou should reload this services :\n- ${to_reload.join('\n- ')}`);
      }
    });

    app.on('get_ownership', ({ error }: { error: boolean }) => {
      this.props.setFreed(false);
      if (error) {
        setFailure('Failed to get ownership');
      } else {
        setSuccess('Successfully got ownership');
        this.props.setLocked(false);
      }
    });

  }

  componentWillReceiveProps(props: Props) {
    this.setResult(this.state.pureBuilt, props.outputOptions.language);
  }



  bufUpdater = () => {
    if (this.state.toUpload !== '' && this.props.env_id) {
      app
        .query(update_env({ env_id: this.props.env_id, content: this.state.toUpload }))
        .send();
      this.setState({ toUpload: '' });
    }
  }

  handleSave = () => {
    if (this.props.env_id && this.props.group_id) {
      app.query(save({ group_id: this.props.group_id, env_id: this.props.env_id })).send();
    }
  }

  handleSource = ({ data, metadata, owner }: { data?: any, metadata?: any, owner?: boolean }) => {
    this.gotSource = true;
    if (metadata) {
      this.setState({ input: metadata.env });
      this.props.setLocked(!owner);
      this.props.setFreed(false);
      return;
    }
    if (data) {
      if (data.freed) {
        this.props.setFreed(true);
        this.props.setInfo('Env just freed');
        return;
      }
      this.setState({ input: data.env });
    }
  };

  setResult = (res: string, language: Language) => {
    this.setState({ result: renderers[language](res) });
  }

  handleBuilt = ({ data, metadata }: { data: string, metadata: any }) => {
    let res = '';
    if (metadata) { res = metadata.env; }
    if (data) { res = data; }
    this.setState({ pureBuilt: res });
    this.setResult(res, this.props.outputOptions.language);
  };

  mountListeners = (env_id: number, group_id: number) => {
    if (env_id) {
      this.unMountSourceFlux = app.on(makeFluxName(env_id, Types.SOURCE), this.handleSource);
      this.unMountBuiltFlux = app.on(makeFluxName(env_id, Types.BUILT), this.handleBuilt);
      app.query(suscribe_env({ env_id, group_id })).send();
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.env_id && prevProps.env_id !== this.props.env_id) {
      if (prevProps.env_id && prevProps.group_id) {
        this.unMountListeners(prevProps.env_id, prevProps.group_id);
      }
      if (this.props.env_id && this.props.group_id) {
        this.mountListeners(this.props.env_id, this.props.group_id);
      }
    }
  }

  componentDidMount = () => {
    this.timeoutBuf = window.setInterval(() => this.bufUpdater(), BUF_REFRESH_TIME);
    if (this.props.env_id && this.props.group_id) {
      this.mountListeners(this.props.env_id, this.props.group_id);
    }
  }

  componentWillUnmount = () => {
    this.unMountListeners(this.props.env_id!, this.props.group_id!);
    clearInterval(this.timeoutBuf);
  }

  validate = () => {
    // TODO
    // placeholder for now
    return true;
  }

  unMountListeners = (env_id: number, group_id: number) => {
    if (this.unMountBuiltFlux && this.unMountSourceFlux) {
      app.query(unsuscribe_env({ env_id })).send();
      this.unMountBuiltFlux();
      this.unMountSourceFlux();
    }
  }

  handleChange = (value: string) => {
    this.setState({ input: value });
    // const parsed = YAML.parse(value);
    if (this.validate() && !this.gotSource) {
      this.setState({ toUpload: value });
    }
    this.gotSource = false;
    // detect changed line(s)
    // if changed line(s) contain the class delimitor = '::'
    // then try to rebuild the file
    // on error let the last valid content
    // should make WS event
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <ControlledEditor
            height="89vh"
            theme='dark'
            width='39vw'
            language="yaml"
            value={this.state.input}
            onChange={(e: any, value?: string) => { if (value) { this.handleChange(value) } return value; }}
            options={{
              readOnly: this.props.locked,
            }}
          />
          <ControlledEditor
            height="89vh"
            theme='dark'
            width='39vw'
            language={this.props.outputOptions.language}
            value={this.state.result}
            options={{
              readOnly: true,
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
        </div>
      </div>
    );
  }
}

const Wrapped = (props: { env_id?: number, group_id?: number }) => {
  const { env_id, group_id } = props;
  const snackbar = useSnackbar();
  const [locked, setLocked] = useRecoilState(lockedState);
  const setFreed = useSetRecoilState(freedState);
  const outputOptions_ = useRecoilValue(outputOptions);
  const setSuccess = (msg: string) => snackbar('check', msg);
  const setFailure = (msg: string) => snackbar('error', msg);
  const setInfo = (msg: string) => snackbar('information', msg);

  return (
    <Editor
      env_id={env_id}
      group_id={group_id}
      locked={locked}
      setLocked={setLocked}
      setFreed={setFreed}
      setSuccess={setSuccess}
      setFailure={setFailure}
      setInfo={setInfo}
      outputOptions={outputOptions_}
    />
  );
}

export default Wrapped;
