/*

prototypes of added function :
function (JSON_object, server_object){
    idiomatic way of sending message is :
    server_object.send_command('name_of_the_command', {
        name_of_param : value
        
        IE: 
        login : login,
        password : password
    })
}

added func will be called when a message with the 'type' field equal 
to their name is received
IE :
on auth the server will respond with a message of type auth :
thus if we do :
const auth = (js, serv) => console.log('authentified');
server.add_func(auth);

'authentified' will be printed automatically on auth


*/


const RECONNECT_TIMEOUT = 1500;
const LOGIN_TOKEN = 'auth';

class Request {
    app: WebsocketedCon;
    requests: any[][];
    /**
     * 
     * @param {WebsocketedCon} app 
     * @param {*} dict 
     */
    constructor(app: WebsocketedCon, dict: any) {
        this.app = app;
        this.requests = [
            [dict]
        ];
    }


    // should take a function which returns a dict
    while = (dict: any) => {
        this.requests.push([dict]);
        return this;
    }


    // should take a function which returns a dict
    after = (dict: any) => {
        const d = this.requests.pop()!;
        d.push(dict);
        this.requests.push(d);
        return this;
    }

    /**
     * Sends the prepared queries
     */
    send = () => this.app.send(JSON.stringify(this.requests));

}
/**
 * 
 * @param {String} type
 * @returns {(d?:any)=>Object}
 */
// export const makeSender = (type: string): (d?: any) => object => dict => ({ type, ...dict });

export function makeSender<T = void>(type: string) {
    return (obj: T) => ({ type, ...obj });
}





type handlerType = {
    [funcName: string]: ((data?: any) => void)[];
}


class ShallowDecoder {
    decode = (s: any) => s;
}

class WebsocketedCon {

    private websocket?: WebSocket;
    private _login: string;
    private password: string;
    auto_reconnect: boolean;
    /**
     * To know if the app should try to auto-login
     */
    loggedOut: boolean;
    /**
 * To know if the app should try to auto-login
 */
    auto_login: boolean;
    /**
     * Adress of the websocket server
     */
    private adrr: string;
    private funcs: handlerType;
    private token?: string;
    private decoder: TextDecoder | ShallowDecoder;

    onStart: () => void;
    onOpen: () => void;
    onLogin: () => void;
    onDisconnect: (con: WebsocketedCon) => void;
    private _onDisconnect: () => void;
    private _onOpen: (evt: Event) => void;
    private _onClose: (evt: Event) => void;
    private _onError: (evt: Event) => void;

    constructor(adrr: string) {
        this.websocket = undefined;
        this._login = '';
        this.auto_reconnect = true;

        this.onDisconnect = () => { };
        this.onStart = () => { };
        this.onOpen = () => { };

        this._onDisconnect = () => { };
        this.onLogin = () => { };

        if (this.auto_reconnect) {
            this._onDisconnect = () => {
                if (!this.loggedOut) {
                    setTimeout(() => this.start(), RECONNECT_TIMEOUT);
                }
            }
        }

        this.decoder = new TextDecoder();
        this.loggedOut = false;

        this.password = '';
        this.auto_login = true;
        this.adrr = adrr;
        this.funcs = {};

        this.token = undefined;

        this._onOpen = (evt) => {
            console.log('opened');
            if (!this.auto_login || this.loggedOut) {
                return;
            }
            this.login();
            this.onOpen();
        };

        this._onClose = () => {
            console.log('closed');
            this._onDisconnect();
            this.onDisconnect(this);
        };

        this._onError = (evt: any) => {
            console.log('error =>', evt.data);
            evt.target.close();
        };

        this.funcs.close = [({ reason }: { reason: string }) => {
            console.log(`closed connection ${reason}`)
        }]
    }

    /**
     * In the case of the use of jsons in the backend, the data is not binary
     * @param evt 
     */
    private loadData = async (evt: MessageEvent) => {
        let d = '';
        try {
            const b = await evt.data.arrayBuffer();
            d = this.decoder.decode(b);
        } catch {
            this.decoder = new ShallowDecoder();
            d = this.decoder.decode(evt.data);
        }
        return d;
    }

    /**
     * @param {MessageEvent} evt
     * @returns {void}
     */
    private _onMessage = async (evt: MessageEvent) => {
        JSON.parse(await this.loadData(evt)).forEach((js: any) => {
            if (js.type === 'error') {
                alert(js.error || 'error {reason unknown}');
                return;
            }
            if (!Object.keys(this.funcs).includes(js.type)) {
                throw new Error(`Invalid type ${js.type}`);
            }
            this.funcs[js.type].forEach(func => func(js));
        });
    };
    public uploadFile = (file: File) => {
        throw new Error('Not implemented');
        // if (this.websocket){
        //     this.websocket.send()
        // }
    }
    /**
     * 
     * @param {String} login 
     * @param {String} password 
     */
    public login = (login_ = '', password = '') => {
        if (this.token && this.token !== 'undefined') {
            this.sendCommand(LOGIN_TOKEN, { token: this.token });
            this.onLogin();
        } else {
            const _login = login_ || this._login;
            const _password = password || this.password;
            if (_login !== '' && _password !== '') {
                this.sendCommand(LOGIN_TOKEN, { login: _login, password: _password });
            }
            this.onLogin();
        }
    }
    /**
     * 
     * @param {Function} func 
     * @param {String} name 
     * @returns {()=>void} Unmount function, will remove the handler, that was just mounted
     */
    public on = (name: string, func: (data?: any) => void) => {
        const n = name || func.name;
        if (this.funcs[n]) {
            this.funcs[n].push(func);
        } else {
            this.funcs[n] = [func];
        }
        return () => this.removeFunc(func, name);
    }
    /**
     * sendCommand
     * Sends a command to the bound app if the socket is openened
     * @param {String} command name of the command
     * @param {Object} params Should be JSONSerializable
     * @returns {void}
     */
    sendCommand = (command: string, params: any): void => {
        if (this.websocket) {
            if (params.type !== undefined) {
                throw new Error('type field cannot be set');
            }
            params.type = command;
            try {
                this.websocket.send(JSON.stringify([
                    [params]
                ]));
            } catch (e) {
                console.log(e);
            }
            // placeholder for now
        } else {
            throw new Error('connection is not opened');
        }
    }
    removeFunc = (func: (data?: any) => void, name: string) => {
        const n = name || func.name;
        const i = this.funcs[n].indexOf(func);
        this.funcs[n].splice(i, 1);
    }
    /**
     * Starts the app
     */
    public start = () => {
        this.onStart();
        this.websocket = new WebSocket(this.adrr);
        this.websocket.onopen = evt => this._onOpen(evt)
        this.websocket.onclose = evt => this._onClose(evt);
        this.websocket.onmessage = evt => this._onMessage(evt);
        this.websocket.onerror = evt => this._onError(evt);
    }
    /**
     * Stop the app
     * @returns void
     */
    public close = () => {
        if (this.websocket) {
            this.websocket.close();
        } else {
            throw new Error('Connection is already closed');
        }

    }

    /**
     * sets the token to be used
     * @param {String} token
     */
    public useToken = (token: string) => { this.token = token; }


    /**
     * Removes the token
     * For when you want to logout
     */
    public clearToken = () => { this.token = undefined; }

    /**
     * Prepares a request to be sent
     */
    public query = (dict: { type: string } & any) => new Request(this, dict);

    /**
     * Sends the given string
     * @param {string | ArrayBuffer | SharedArrayBuffer | Blob | ArrayBufferView} data
     */
    send = (data: string | ArrayBuffer | SharedArrayBuffer | Blob | ArrayBufferView) => {
        if (this.websocket) {
            this.websocket.send(data);
        } else {
            throw new Error('Connection is not opened');
        }

    }

    /**
     * Sets the credentials for the app
     * @param {String} login
     * @param {String} password
     */
    public setCredentials = (login: string, password: string) => {
        this._login = login;
        this.password = password;
    }
}

export default WebsocketedCon;


export const makeTokenManager = (tokenName: string) => {
    /**
     * Returns the current registered token
     */
    const getToken = () => localStorage.getItem(tokenName);


    /**
     * Deletes the current registered token
     */
    const deleteToken = () => localStorage.removeItem(tokenName);


    /**
     * Registers a websocket token
     * @param {string} token ws_token
     */
    const setToken = (token: string) => localStorage.setItem(tokenName, token);

    return { getToken, deleteToken, setToken };
}


