import { atom } from "recoil";

const defaultTheme: ThemeState = {
    type: "dark",
}

type ThemeState = {
    type: "dark" | "light"
}

export const themeState = atom<ThemeState>({
    key: 'themeSate',
    default: defaultTheme,
});

const settingsToken = "SETTTINGS";

export const getSettings = (): ThemeState => {
    const res = localStorage.getItem(settingsToken);
    return res ? JSON.parse(res) : defaultTheme;
}

export const setSettings = (settings: ThemeState) => {
    localStorage.setItem(settingsToken, JSON.stringify(settings))
};