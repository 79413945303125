import React, { memo } from 'react';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
    delete: {
        height: '1em',
        width: '1em'
    },
    button: {
        backgroundColor: "#FF4C4C",
        color: "white",
        // ...props.style,
    },
});


const DeleteButton: React.FC<ButtonProps> = memo(props => {
    const classes = useStyles();
    return (
        <Button {...props} variant="contained" className={classes.button}>
            <DeleteIcon fontSize="small" className={classes.delete} />
        </Button>
    );
})


export default DeleteButton;