import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { app, deleteToken as deleteWSToken, } from "../../../api/api";
import { BasicUser } from "../../../store/user/type";
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Typography, IconButton, Menu, MenuItem, Badge } from "@material-ui/core";

const handleLogout = () => {
    app.loggedOut = true;
    app.close();
    app.clearToken();
    deleteWSToken();
    app.start();
};

const ProfileButton = memo(({ user, setTab }: { user?: BasicUser, setTab: any }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(null);

    const history = useHistory();
    return (
        <div style={{ marginLeft: 'auto' }}>
            <Typography variant="h6">
                {user?.username || '?'}
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <Badge color="secondary" badgeContent={user?.messages_nb}  invisible={!user ? false : user?.messages_nb < 1}>
                        <AccountCircle />
                    </Badge>
                </IconButton>

            </Typography>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        history.push('/profile');
                        setTab(-1);
                        handleClose();
                    }}>
                    Profile
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    handleLogout()
                }}>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    )
});


export default ProfileButton;