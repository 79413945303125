import YAML from "yamljs";

export const IMPORT_TOKEN = 'from';
export const CLASS_TOKEN = '::';

export const detectChangedLines = (old: string, _new: string) => {
  const oldLines = old.split('\n');
  return _new.split('\n').filter((line, i) => line !== oldLines[i]);
};


export const detectImports = (parsed: any): string[] => {
  const arr = parsed[IMPORT_TOKEN];
  if (arr && Array.isArray(arr)) {
    return arr.filter(e => e);
  }
  return [];
};

export const JsonRenderer = (s: string) => {
  return JSON.stringify(YAML.parse(s), null, 4);
}

export const YamlRenderer = (s: string) => {
  return s;
}
export const FlatRenderer = (s: string) => {
  return renderFlat(flattenObject(YAML.parse(s)));
}

const sep = '_';

function flattenObject(ob: any) {
  const toReturn: any = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) {
      continue;
    }
    if ((typeof ob[i]) == 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[`${i}${sep}${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

const renderFlat = (d: any) => {
  const res:string[]= [];
  Object.keys(d).forEach(key => {
    const value = d[key];
    res.push(`${key}=${value}`);
  });
  return res.join('\n');
}

export const renderers = {
  "json": JsonRenderer,
  "yaml": YamlRenderer,
  "flat": FlatRenderer
}

export enum Types {
  SOURCE = 'source',
  BUILT = 'built',
}


export const makeFluxName = (env_id: number, type: Types) => `${env_id}_${type === Types.BUILT ? 'b' : 's'}`;

