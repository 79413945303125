import { useAlert } from "../../Alert/atoms";
import { usePassword } from "../../Prompt/atoms";
import { useState } from "react";
import { useUserSelector } from "../../Selector/UserSelector";
import { useModal } from "../../../../utils/utils";
import Tokens from "../../Token/TokenComponent";
import { app, get_allies, useHandler, add_user_to_group, delete_env, get_filenames } from "../../../../api/api";


export type SelectedFile = { groupId: number, fileId?: number };

export interface MenuOption<T> {
  label: JSX.Element | React.ReactText,
  handler: (
    item: T,
    /**
     * Used to close the menu
     */
    handleClose: () => void
  ) => void;
  for: ("group" | "file")[]
}


export const useOptions = () => {
    const showAlert = useAlert();
    const promptPassword = usePassword();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<{ username: string }[]>([]);
    const [selectedFunction, setSelectedFunction] = useState<(username: string) => void | undefined>();
    const { Selector:UserSelector, setOpen, setClose } = useUserSelector('Allies', users, selectedFunction!, loading);
    const [env_id, setEnvId] = useState<number>();
    const [group_id, setGroupId] = useState<number>();
    const { Modal: TokenModal, setOpen: setTokenOpen } = useModal(Tokens, false, { env_id, group_id });
  
    const loadUsers = () => {
      setLoading(true);
      app
        .query(get_allies())
        .send();
    };
  
    useHandler('get_allies', ({ allies }: { allies: string[] }) => {
      setUsers(allies.map(username => ({ username })));
      setLoading(false);
    });
  
    const add_ally_to_group = (username: string, groupId: number, handleClose: () => void) => {
      showAlert(`Do you really want to add ${username} to the group ?`, async () => {
        try {
          const { password } = await promptPassword();
          setClose();
          handleClose();
          app.query(add_user_to_group({ username, group_id: groupId, password })).send();
        } catch (e) { }
      });
    };
  
    // const remove_ally_from_group = (username: string, groupId: number, handleClose: () => void) => {
  
  
  
    //   setClose();
    // }
  
  
  
    const res: MenuOption<SelectedFile>[] = [{
      for: ["group", "file"],
      label: 'Delete',
      handler: (selected: SelectedFile, handleClose: () => void) => {
        const { fileId, groupId } = selected;
        if (fileId) { // else delete folder
          showAlert('Do you want to delete this file ?', () => {
            handleClose();
            app
              .query(delete_env({ env_id: fileId, group_id: groupId }))
              .after(get_filenames())
              .send();
          });
        }
      },
    },
    {
      for: ["group"],
      label: 'Invite user',
      handler: (selected: SelectedFile, handleClose: () => void) => {
        const { groupId } = selected;
        // pour les mêmes raisons que pour l'atom
        setSelectedFunction(() => (username: string) => add_ally_to_group(username, groupId, handleClose));
        loadUsers();
        setOpen();
      },
    }, {
      for: ["file"],
      label: 'Manage tokens',
      handler: (selected: SelectedFile, handleClose: () => void) => {
        setEnvId(selected.fileId);
        setGroupId(selected.groupId);
        setTokenOpen();
        handleClose();
      }
    }
    ]
    return { options: res, UserSelector, TokenModal };
  }