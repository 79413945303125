import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, makeStyles, DialogTitle } from '@material-ui/core';
import { promptOpenState, promtFuncState, promptFieldOptions, Fields } from './atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import TextField from '../TextField';

const useStyles = makeStyles(() => ({
    dialog: {

    }
}));

const prepareFields = (fields: Fields<any>) => {
    const res: { [x: string]: string } = {};
    Object.keys(fields).forEach(name => res[name] = '');
    return res;
};

const Prompt = () => {
    const [state, setOpen] = useRecoilState(promptOpenState);
    const { open, title } = state;
    const fields = useRecoilValue(promptFieldOptions);
    const [values, setValues] = useState(prepareFields({}));
    const { resolve, reject } = useRecoilValue(promtFuncState);

    const onClose = () => {
        setOpen({
            open: false,
            title: undefined,
        });
    }

    const onClosed = () => {
        setValues({});
    }

    const onSubmit = () => {
        resolve(values);
        onClose();
    };

    const handleClose = () => {
        reject(values);
        onClose();
    }

    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.dialog}
            onExited={onClosed}
            onEnter={() => setValues(prepareFields(fields))}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <form onSubmit={e => e.preventDefault()}>
                <DialogContent>
                    {Object.keys(values).map((name, i) => {
                        const f = fields[name];
                        // console.log(name, f, values, values[name]);
                        return (
                            <>
                                <TextField
                                    autoFocus={i === 0}
                                    key={name}
                                    label={f.label || name}
                                    type={f.type || "text"}
                                    // value={values[name] || 'deb'}
                                    onChange={e => setValues(old => {
                                        // console.log('old', old, e.target.value);
                                        old[name] = e.target.value;
                                        // console.log('new', old);
                                        return old;
                                    })}
                                />
                                <br />
                            </>
                        )
                    })}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} type="submit" variant="contained" color="primary">
                        Validate
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
};

export default Prompt;
