import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';



export default (props: TextFieldProps) => (
    <TextField
        {...props}
        inputProps={{ style: { textAlign: 'center', ...props.style } }}
        variant="outlined"
        margin="dense"
    />
)




