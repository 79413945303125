import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid/Grid";
import Paper from '@material-ui/core/Paper';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { app, deleteToken as deleteWsToken, getToken as getWSToken, setToken as setWSToken } from '../../../api/api';
import { AuthMessage } from '../../../api/types';
import LoadingComponent from '../../common/LoadingComponent/LoadingComponent';
import TextField from '../../common/TextField';
import { loggedInState } from './atoms';
import { useTitle } from '../../../utils/utils';

// cuz the server won't respond on false password
const connectionTimeout = 1000;



const Loger = () => {
  useTitle('Login');
  const changeLoggedIn = useSetRecoilState(loggedInState);
  const [pending, setPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    app.on('auth', ({ status, token, error }: AuthMessage) => {
      if (status) {
        changeLoggedIn(true);
        app.loggedOut = false;
        setWSToken(token);
      }
      if (error) {
        setErrorMessage(error);
        setPending(false);
      }
    });

    // sets the login view if we get disconnected
    app.onDisconnect = () => {
      changeLoggedIn(false);
      if (!app.loggedOut) {
        setPending(true);
      }
    };

    app.onLogin = () => {
      setPending(true);
    }

    app.onOpen = () => {
      setPending(false);
    }
  }, [changeLoggedIn]);

  const handleFailedPassword = () => {
    setPassword('');
    setFailed(true);
    setPending(false);
  }

  const handleLogin = useCallback(() => {
    deleteWsToken();
    app.clearToken();
    app.login(login, password);
    setTimeout(() => handleFailedPassword(), connectionTimeout);
  }, [login, password]);

  
  useEffect(() => {
    const token = getWSToken();
    if (token) {
      app.useToken(token);
      app.auto_login = true;
    }
  }, []);

  return (
    <form onSubmit={handleLogin}>
      <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
        <Paper style={{ textAlign: 'center', padding: '2%', width: '30em', height: '25em' }}>
          <Typography variant="h2">
            Login
          </Typography>
          <LoadingComponent loading={pending}>
            <TextField autoFocus type="text" label="Username"
              onChange={e => setLogin(e.target.value)}
            />
            <br />
            <TextField type="password" label="Password"
              onChange={e => setPassword(e.target.value)}
              value={password}
            />
            <br />
            <br />
            <Button type="submit" variant="contained" onClick={handleLogin} value="Login">
              Login
            </Button>
            {failed && <p>Invalid Password</p>}
            {errorMessage && <p>{errorMessage}</p>}
          </LoadingComponent>
        </Paper>
      </Grid>
    </form>
  )
}




export default Loger;