import { IconButton, Menu, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import LanguageSelector from './LanguageSelector';

type Props = {

}

const MoreOptions = (props: Props) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const open = Boolean(anchorEl);
    const handleClose = () => setAnchorEl(undefined);
    return (
        <>
            <div style={{ marginLeft: 'auto' }} >
                <Tooltip title="More options">
                    <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            </div>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <div style={{ paddingRight: '1em', display: 'flex' }}>
                    <LanguageSelector />
                </div>
            </Menu>
        </>
    );
}

export default MoreOptions;