import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from "@devexpress/dx-react-core";
import { makeStyles, Theme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
	div: {
		display: "flex",
		flex: 1,
	},
	icon: {
		color: theme.palette.primary.contrastText,
	},
}));

export default ({ loading, getData }: { loading?: boolean; getData?: () => void }) => {
	const classes = useStyles();
	const [internalLoading, setInternalLoading] = useState(false);
	const [nbLoop, setNbLoop] = useState(0);
	const iconRef = useRef(null);
	const [timer, setTimer] = useState<any>();

	useEffect(() => {
		if (!loading && timer !== undefined && nbLoop !== 0 && iconRef.current) {
			setInternalLoading(false);
			(iconRef.current! as HTMLElement).classList.toggle("spinning");
			clearInterval(timer);
			setTimer(undefined);
			setNbLoop(0);
		}
	}, [nbLoop, loading, timer]);

	useEffect(() => {
		if (loading && timer === undefined && iconRef.current !== null) {
			setInternalLoading(true);
			(iconRef.current! as HTMLElement).classList.toggle("spinning");
			setTimer(setInterval(() => setNbLoop(nb => nb + 1), 375));
		}
	}, [loading, timer]);
	const onClick = () => {
		getData && getData();
	};
	return (
		<Plugin name="FilterToggle">
			<Template name="toolbarContent">
				<TemplatePlaceholder />
				<TemplateConnector>
					{() => (
						<>
							{(getData || loading) && (
								<div className={classes.div}>
									<IconButton onClick={onClick} disabled={internalLoading}>
										<AutorenewIcon ref={iconRef} className={classes.icon} />
									</IconButton>
								</div>
							)}
						</>
					)}
				</TemplateConnector>
			</Template>
		</Plugin>
	);
};
