import React, { memo, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { app, get_account_data, get_filenames, useHandler } from '../../../api/api';
import { GroupView } from '../../../api/types';
import { prepareGroup, useGroupIdEnvId } from '../../../utils/utils';
import EnvEditor from '../../common/EnvEditor/Editor';
import MenuContent from '../../common/EnvEditor/MenuContent/MenuContent';

export type RouteParams = {
    env_id?: string;
    group_id?: string;
};

type Props = {} & RouteComponentProps<RouteParams>;


const Editor = memo((props: Props) => {
    const { env_id: routeEnvId, group_id: routeGroupId } = useGroupIdEnvId(props);

    const [group, setGroup] = useState<GroupView | undefined>();

    useHandler('get_filenames', ({ filenames: groups }) => {
        const tree = prepareGroup(groups);
        setGroup(tree);
    });

    useEffect(() => {
        app
            .query(get_filenames())
            .while(get_account_data())
            .send();
    }, []);


    return (
        // <RecoilRoot>
        <div style={{ display: 'flex', width: '100vw' }}>
            <MenuContent group={group} />
            <EnvEditor
                env_id={routeEnvId}
                group_id={routeGroupId}
            />
        </div>
        // </RecoilRoot>
    )
})



export default Editor;