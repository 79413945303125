import { Card, CircularProgress, makeStyles } from '@material-ui/core';
import React, { ReactNode, forwardRef, memo, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Alert from '../Alert/Alert';
import Snackbar from '../Snackbar/Snackbar';
import AppBar from './AppBar';
import { routes } from './routes';
import Prompt from '../Prompt/Prompt';



type TabContainerProps = {
    children: ReactNode;
};

const useStyles = makeStyles(() => ({
    card: {
        minHeight: '83vh',
        margin: '0.4%',
        padding: '0.4em 0.2em'
    },
}));


const TabContainer = memo(forwardRef((props: TabContainerProps, ref) => {
    const classes = useStyles();
    return (
        <Card className={classes.card} ref={ref}>
            {props.children}
        </Card >
    )
}));

const Router = () => {

    return (
        <>
            <Snackbar />
            <Alert />
            <AppBar />
            <Prompt />
            <Suspense
                fallback={<CircularProgress />}
            >
                <TabContainer>
                    <Switch>
                        {routes.map((route, i) => (
                            <Route path={route.to} component={route.component} key={i} />
                        ))}
                        <Redirect to="/editor/" />
                    </Switch>
                </TabContainer>
            </Suspense>
        </>
    );
}

export default Router;