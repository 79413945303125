import { FormControl, Theme } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/styles";
import { FormikProps } from "formik";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useUID } from "react-uid";

export type OptionsType<T> = { label: string; value: T };

type Props<T> = {
	options: OptionsType<T>[];
	form?: FormikProps<T>;
	field?: { name: string; value: T };
	name?: string;
	label?: string;
	style?: any;
	disabled?: boolean;
	onChange?: (event: ChangeEvent<{ label?: string; value: OptionsType<T> }>) => void;
	initialValue?: OptionsType<T>;
};

const useStyles = makeStyles((theme: Theme) => ({
	icon: {
		color: theme.palette.primary.main,
	},
	menuItem: {
		"&:hover": {
			backgroundColor: theme.palette.primary.main + "8C",
		},
	},
	formControl: {
		margin: theme.spacing(1),
		maxWidth: 200,
		width: "100%",
	},
	inputLabel: {
		color: theme.palette.primary.main,
	},
}));

const useInputStyles = makeStyles((theme: Theme) => ({
	focused: {},
	notchedOutline: {},
	root: {
		color: theme.palette.primary.main,
		display: "flex",
		flexWrap: "wrap",
		"& $notchedOutline": {
			borderColor: theme.palette.primary.main,
		},
		"&:hover $notchedOutline": {
			borderColor: theme.palette.primary.main,
		},
		"&$focused $notchedOutline": {
			borderColor: theme.palette.primary.main,
		},
	},
}));

/**
 * /!\ Warning this component may break with formik updates
 */
const SelectField = function <T>(props: Props<T>) {
	const { options } = props;

	const renderedOptions = useMemo(() => options.map(e => ({ label: e.label, value: e })), [options]);

	const [labelWidth, setLabelWidth] = useState(0);
	const [value, setValue] = useState(props.initialValue || "");
	const [inputLabelRef, setInputLabelRef] = useState<HTMLLabelElement>();

	useEffect(() => {
		inputLabelRef && setLabelWidth(inputLabelRef.offsetWidth);
	}, [props.label, inputLabelRef]);

	const handleChange = (event: ChangeEvent<{ label?: string; value: OptionsType<T> }>) => {
		setValue(event.target.value.label || "");
		props.form && props.form.setFieldValue(props.field!.name, event.target.value.value, true);
		props.onChange && props.onChange(event);
	};
	const classes = useStyles();
	const inputClasses = useInputStyles();
	const uid = useUID();
	return (
		<FormControl variant="outlined" margin="dense" className={classes.formControl} style={props.style}>
			<InputLabel classes={{ root: classes.inputLabel }} htmlFor={uid} ref={(ref: HTMLLabelElement) => setInputLabelRef(ref)}>
				{props.label}
			</InputLabel>
			<Select
				id={uid}
				onChange={(e) => handleChange(e as ChangeEvent<{ label?: string; value: { value: T; label: string } }>)}
				value={value}
				disabled={props.disabled}
				classes={{ icon: classes.icon }}
				renderValue={(value: any) => <>{value.label || value}</>}
				input={<OutlinedInput name={props.name} classes={inputClasses} labelWidth={labelWidth} />}>
				{renderedOptions.map((option: any) => (
					<MenuItem value={option.value} className={classes.menuItem} key={option.label}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default SelectField;

export function useSelectField<T>(options: OptionsType<T>[], label?: string, style?: any, initialValue?: OptionsType<T>): [T | undefined, JSX.Element] {
	const [value, setValue] = useState<T>();
	const onChange = (event: ChangeEvent<{ label?: string; value: OptionsType<T> }>) => {
		setValue(event.target.value.value);
	};

	const Field = <SelectField options={options} label={label} style={style} initialValue={initialValue} onChange={onChange} />;
	return [value, Field];
}
