import React, { useEffect, useState, useCallback } from 'react';
import { FileView, GroupView } from '../api/types';
import { RouteComponentProps } from 'react-router-dom';
import { RouteParams } from '../components/pages/Editor/Editor';
import { useSetRecoilState } from 'recoil';
import { fileIdState, groupIdState } from '../components/pages/Editor/atoms';




export const useTitle = (title: string) => {
    useEffect(() => {
        document.title = title;
    }, [title]);
}

// deprecated
export const filenamesToTree = (files: FileView[]): GroupView => {
    const res: GroupView = {
        id: 0,
        name: '',
        files: [],
        sub_groups: [],
    };
    files.forEach(file => {
        console.log('file', file);
        const { filename } = file;
        const s = filename.split('/');
        const realFilename = s[s.length - 1];
        const restPath = s.slice(0, -1);
        const prevNode = res;
        if (restPath.length > 0) {
            restPath.forEach(p => {
                const prev = prevNode.sub_groups.find(s => s.name === p);
                if (prev) {
                    prev.files.push({ ...file, filename: realFilename });
                } else {
                    prevNode.sub_groups.push({
                        id: file.group_id,
                        name: p,
                        files: [{ ...file, filename: realFilename }],
                        sub_groups: [],
                    })
                }
            });
        } else {
            res.files.push(file);
            if (!res.id) {
                res.id = file.group_id;
            }
        }
    });
    return res;
}


export const prepareGroup = (groups: { group_name: string, group_id: number, files: { [file_id: string]: string } }[]) => {
    const preparedGroups = groups.map(g => ({
        ...g,
        files: Object.keys(g.files)
            .map(key => ({
                group_id: g.group_id,
                file_id: +key,
                filename: g.files[key],
            }))
    }));

    const tree: GroupView = {
        id: 0,
        name: '',
        files: [],
        sub_groups: [],
    };
    // console.log('prepared', preparedGroups);
    preparedGroups.forEach(g => {
        const splitedName = g.group_name.split('/');
        splitedName.shift();
        // console.log('name', splitedName);

        // todo : c'est moche

        const ge: GroupView = {
            name: splitedName[splitedName.length - 1],
            id: g.group_id,
            files: g.files,
            sub_groups: [],
        };

        if (splitedName[0] !== '') {
            let prevNode: GroupView | undefined = tree;
            for (const namePart of splitedName.slice(0, -1)) {
                const a: GroupView | undefined = prevNode.sub_groups.find(n => n.name === namePart);
                if (!a) {
                    console.log('missing', namePart);
                    prevNode.sub_groups.push({
                        name: namePart,
                        id: 0,
                        files: [],
                        sub_groups: []
                    });
                } else {
                    prevNode = a;
                }
            }
            prevNode.sub_groups.push(ge);
        } else {
            // TODO
            // normalement on fait ça une seule fois, => faire opti
            if (!tree.id) {
                tree.id = g.group_id;
                tree.name = g.group_name;
                tree.files = g.files;
            }
        }
    });

    return tree;
};

export const useGroupIdEnvId = (props: RouteComponentProps<RouteParams>) => {
    const group_id_string = props.match.params.group_id;
    const group_id = group_id_string ? +group_id_string : undefined;
    const env_id_string = props.match.params.env_id;
    const env_id = env_id_string ? +env_id_string : undefined;
    const setEnv_id = useSetRecoilState(fileIdState);
    const setGroup_id = useSetRecoilState(groupIdState);
    
    useEffect(() => {
        setEnv_id(env_id);
    }, [env_id, setEnv_id]);
    useEffect(() => {
        setGroup_id(group_id);
    }, [group_id, setGroup_id]);

    return { group_id, env_id };
}


export const format = (string: string, args: any) => {
	let this_string = string;
	for (const key in args) {
		let string_key = "{" + key + "}";
		this_string = this_string.replace(new RegExp(string_key, "g"), args[key]);
	}
	return this_string;
};

export const dateForHuman = (s: string) => new Date(s).toLocaleString();

interface modalProps {
    open: boolean;
    handleModalClose: () => void;
    loading?: boolean;
}

export function useModal<T>(Modal: React.FunctionComponent<modalProps>, loading = false, modalProps?: T) {
    const [open, setOpen] = useState(false);
    const handleClose = useCallback(() => setOpen(false), []);
    const open_ = useCallback(() => setOpen(true), []);
    const Modal_ = <Modal {...modalProps} loading={loading} open={open} handleModalClose={handleClose} />;
    return {open, setOpen: open_, setClose: handleClose, Modal: Modal_ };
}